@import 'fonts.css';
@import 'slider.css';

input[type='time']::-webkit-calendar-picker-indicator,
input[type='date']::-webkit-calendar-picker-indicator {
  opacity: 0;
}
input[type='time']::-webkit-date-and-time-value,
input[type='date']::-webkit-date-and-time-value {
  text-align: left;
}

body {
  --fold: max(100vh, 700px);
}

@media only screen and (min-width: 37.5rem) {
  body {
    --fold: max(100vh, 720px);
  }
}

@supports (height: 100dvh) {
  body {
    --fold: max(100dvh, 700px);
  }

  @media only screen and (min-width: 37.5rem) {
    body {
      --fold: max(100dvh, 720px);
    }
  }
}
