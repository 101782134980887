.sliderWrapper {
  width: calc(100% - -48px);
  height: 100%;
  margin: 0 -24px;
  border-radius: 6px;
  overflow: hidden;
}

.sliderWrapper .carousel {
  /* Leave space for control dots */
  height: calc(100% - 16px);
}

.sliderWrapper .slider-wrapper {
  max-width: 900px;
  margin: 0 auto;
  height: calc(100% - 16px);
}

.sliderWrapper .slider {
  height: 100%;
}

.sliderWrapper .carousel .control-dots {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;
}

.sliderWrapper .carousel.carousel-slider {
  overflow: inherit;
  max-width: 1430px;
  margin: 0 auto;
}

.resultSlider {
  margin: 0 auto 3rem;
  width: 100%;
  max-width: 900px;
}

.slider-wrapper .slide picture img {
  aspect-ratio: 2/1;
}

.doubleSuggestedCard,
.doubleSuggestedCard>span {
  aspect-ratio: 7/4;
}

.suggestedCardImgWrapper,
.suggestedCardImgWrapper>span {
  aspect-ratio: 4/1;
}

.cardImgWrapper,
.cardImgWrapper>span {
  aspect-ratio: 1 / 1;
}

.suggestedCardImgWrapper::after,
.doubleSuggestedCard::after,
.cardImgWrapper::after {
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: block;
  content: ' ';
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 30%);
  z-index: 0;
  cursor: pointer;
}

.timeWrapper .carousel-root {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .sliderWrapper {
    width: 100%;
    margin: 0 0 1.25rem;
  }

  .resultSlider {
    display: none;
  }

  .resultSlider .slide span {
    min-height: 430px !important;
  }

  .suggestedCardImgWrapper,
  .suggestedCardImgWrapper>span {
    aspect-ratio: 1.45;
  }

  .doubleSuggestedCard>span {
    aspect-ratio: 7/4.7;
  }

  .slider-wrapper .slide picture img {
    aspect-ratio: 1.45;
  }

  .sliderWrapper .carousel .chakra-button:active,
  .sliderWrapper .carousel .chakra-button:focus {
    background: #fff !important;
  }
}