@font-face {
  font-family: 'Nike';
  src: local('Nike'),
    url(/assets/fonts/Nike-Futura-ND-cn-xb.ttf) format('truetype');
}

@font-face {
  font-family: 'HelveticaNeue';
  src: local('HelveticaNeue'),
    url(/assets/fonts/HelveticaNeue.ttf) format('truetype');
}

@font-face {
  font-family: 'HelveticaNeueBold';
  src: local('HelveticaNeueBold'),
    url(/assets/fonts/HelveticaNeueBold.ttf) format('truetype');
}

@font-face {
  font-family: 'HelveticaNeueThin';
  src: local('HelveticaNeueThin'),
    url(/assets/fonts/HelveticaNeueThin.ttf) format('truetype');
}

@font-face {
  font-family: 'NikeFutura';
  src: local('NikeFutura'),
    url(/assets/fonts/Nike-Futura-ND-cn-xb-ob.otf) format('truetype');
}